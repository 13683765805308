import * as React from "react";
import "../stylesheets/styles.sass";
import '../font-awesome';
import '../stylesheets/bootstrap.css'; 
import Header from './components/header';
import Logo from './components/logo'
import News from './components/news';
import Audio from "./components/audio";
import Video from "./components/video";
import Gallery from "./components/gallery";
import Shows from "./components/shows";
import Contact from "./components/contact";
import Footer from "./components/footer";
// markup

global.jQuery = require('jquery');

const IndexPage = () => {
  return (
    <main>
      <title>We Are in Plastic</title>
      <div class="wrapper d-flex flex-column">
      <Header></Header>
      <Logo></Logo>
      <News></News>
      <Audio></Audio>
      <Video></Video>
      <Gallery></Gallery>
      <Shows></Shows>
      <Contact></Contact>
      <Footer></Footer>
      <a class="back-to-top text-center" onClick={scrollUp} href="#home"><i class="fa fa-arrow-up"></i></a>
      </div>
    </main>
  )
}

export default IndexPage

function scrollUp(){
  if (typeof window !== `undefined`) { 
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
  }
}