import * as React from "react";
import MainMenu from '../../data/menu.json';
import Socials from '../../data/links.json';

const Header = () => {
    return (
       <header className="page-header" id="home"> 
        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-dark">
              <a className="navbar-brand" href="#">Home</a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="true" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse order-0" id="navbarNav">
                  <ul className="navbar-nav">
                    {MainMenu.menu.map(menuItem => (
                        <li className="nav-item">
                            <a className="nav-link" href={menuItem.url}>{menuItem.text}</a>
                        </li>
                        
                    ))}
                  </ul> 
              </div>
              <ul className="social text-right order-2 d-flex flex-row my-2 my-lg-0">
                {Socials.links.map(link => (
                    <li className="social-item mr-3">
                      <a href={link.url}> 
                        <i className={link.icon}></i> 
                      </a>
                  </li>
                ))} 
              </ul>
          </nav>
        </div>  
      </header>
    )
  }

export default Header;
